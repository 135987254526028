import React from 'react';
import { graphql } from 'gatsby';

import TransformNav from '../components/TransformNav';
import ContentBlocks from '../components/blocks/ContentBlocks';
import PageTransition from '../components/PageTransition';
import SEO from '../components/Seo';

export default function Homepage({ data }) {
  const { page } = data;

  return (
    <>
      <PageTransition>
        <TransformNav />
        <SEO title={data.page.seo_title} desc={data.page.seo_description} />
        {page._rawBlocks ? <ContentBlocks blocks={page._rawBlocks} /> : null}
      </PageTransition>
    </>
  );
}

export const query = graphql`
  query Index {
    artists: allSanityArtist {
      nodes {
        _id
        name
        _rawRichText
        slug {
          current
        }
        discipline {
          name
        }
        actueel
        image {
          asset {
            fluid(maxWidth: 2100) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    events: allSanityEvent {
      nodes {
        _id
        name
        location
        place
        startDate
        endDate
        slug {
          current
        }
      }
    }
    page: sanityPage(slug: { current: { eq: "home" } }) {
      name
      seo_title
      seo_description
      _rawBlocks(resolveReferences: { maxDepth: 10 })
    }
    footer: allSanitySiteSettings {
      edges {
        node {
          address_1
          address_2
          address_3
          facebook_url
          email
          instagram_url
          phone
          studio_title
          title
          title_1
          title_2
          vimeo_url
        }
      }
    }
  }
`;
